<template>
  <v-navigation-drawer
      :value="isAddNewRoleSidebarActive"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
      app
      @input="(val) => $emit('update:is-add-new-role-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Role</span>
        <v-spacer></v-spacer>
        <v-btn
            icon
            small
            @click="$emit('update:is-add-new-role-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="onSubmit"
        >
          <v-text-field
              v-model="roleData.name"
              outlined
              dense
              :rules="[validators.required]"
              label="Role Name"
              placeholder=""
              hide-details="auto"
              class="mb-6"
          ></v-text-field>

          <v-select
              v-model="roleData.permissions"
              :rules="[validators.required]"
              label="Permissions"
              :items="permissionOptions"
              item-text="name"
              item-value="name"
              outlined
              dense
              hide-details="auto"
              class="mb-6"
              multiple
          >
          </v-select>

          <v-btn
              color="primary"
              class="me-3"
              type="submit"
          >
            Add
          </v-btn>
          <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetRoleData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import {mdiClose} from "@mdi/js"
import countries from "@/@fake-db/data/other/countries"
import store from "@/store"
import {ref} from "@vue/composition-api"
import {required, emailValidator} from "@core/utils/validation"

export default {
  model: {
    prop: "isAddNewRoleSidebarActive",
    event: "update:is-add-new-role-sidebar-active",
  },
  props: {
    isAddNewRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionOptions: {
      type: Array,
      required: true,
    },
    permissionOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, {emit}) {
    const blankRoleData = {
      name: "",
      permissions: [],
      guard_name: 'admin'
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const roleData = ref(JSON.parse(JSON.stringify(blankRoleData)))
    const resetRoleData = () => {
      roleData.value = JSON.parse(JSON.stringify(blankRoleData))
      resetForm()
      emit("update:is-add-new-role-sidebar-active", false)
    }

    const onSubmit = () => {
      if (valid.value) {
        store.dispatch("am-role/addRole", roleData.value).then(() => {
          emit("refetch-data")
          emit("update:is-add-new-role-sidebar-active", false)
        })

        resetRoleData()
      } else {
        validate()
      }
    }

    return {
      resetRoleData,
      form,
      onSubmit,
      roleData,
      valid,
      validate,

      // validation
      validators: {required},
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
